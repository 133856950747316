import React, { Fragment, useState, useContext } from "react";
import Swal from "sweetalert2";
import { withRouter } from "react-router-dom";
import clienteAxios from "../../config/axios";

// import el Context
import { CRMContext } from "../../context/CRMContext";

function NuevoCliente({ history }) {
  // utilizar valores del context
  const [auth] = useContext(CRMContext);

  // cliente = state, guardarcliente = funcion para guardar el state
  const [cliente, guardarCliente] = useState({
    first_name: "",
    last_name: "",
    password: "",
    email: "",
    active: "",
  });

  // leer los datos del formulario
  const actualizarState = (e) => {
    // Almacenar lo que el usuario escribe en el state
    guardarCliente({
      // obtener una copia del state actual
      ...cliente,
      [e.target.name]: e.target.value,
    });
  };

  // Añade en la REST API un cliente nuevo
  const agregarCliente = (e) => {
    e.preventDefault();

    // enviar petición
    clienteAxios.post("/usuarios", cliente, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }).then((res) => {
      // validar si hay errores de mongo
     // console.log(res.data.code);
      if (res.data.code === "23505") {
        Swal.fire({
          type: "error",
          title: "Hubo un error",
          text: "Ese cliente ya esta registrado",
        });
      } else {
        Swal.fire("Se agregó el Cliente", res.data.mensaje, "success");
      }
      // Redireccionar
      history.push("/");
    });
  };

  // Validar el formulario
  const validarCliente = () => {
    // Destructuring
    const { first_name, last_name, email, password, active } = cliente;

    // revisar que las propiedades del state tengan contenido
    let valido =
      !first_name.length ||
      !last_name.length ||
      !email.length ||
      !password.length ||
      !active.length;

    // return true o false
    return valido;
  };

  // verificar si el usuario esta autenticado o no
  if (!auth.auth && localStorage.getItem("token") === auth.token) {
    history.push("/iniciar-sesion");
  }

  return (
    <Fragment>
      <h2>Nuevo Cliente</h2>

      <form onSubmit={agregarCliente}>
        <legend>Llena todos los campos</legend>
        <div className="campo">
          <label>Nombre:</label>
          <input
            type="text"
            placeholder="Nombre User"
            name="first_name"
            onChange={actualizarState}
          />
        </div>

        <div className="campo">
          <label>last_name:</label>
          <input
            type="text"
            placeholder="last_name Cliente"
            name="last_name"
            onChange={actualizarState}
          />
        </div>

        <div className="campo">
          <label>password:</label>
          <input
            type="password"
            placeholder="password Cliente"
            name="password"
            onChange={actualizarState}
          />
        </div>

        <div className="campo">
          <label>Email:</label>
          <input
            type="email"
            placeholder="Email User"
            name="email"
            onChange={actualizarState}
          />
        </div>

        <div className="campo">
          <label>Activo</label>
          <input
            type="checkbox"
            placeholder="Activar User"
            name="active"
            onChange={actualizarState}
          />
        </div>

        <div className="enviar">
          <input
            type="submit"
            className="btn btn-azul"
            value="Agregar Cliente"
            disabled={validarCliente()}
          />
        </div>
      </form>
    </Fragment>
  );
}

// HOC, es una función que toma un componente y retorna un nuevo componente
export default withRouter(NuevoCliente);
