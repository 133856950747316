import React, { useEffect, useState, useContext } from "react";
import clienteAxios from "../../config/axios";
import { CRMContext } from "../../context/CRMContext";
import { Link } from "react-router-dom";
import { ExportToExcel } from './ExportToExcel'

const Tableropartido = (props) => {
    const [auth] = useContext(CRMContext);
    const [usuarios, guardarUsuarios] = useState([])

    if (auth.token === "")
        props.history.push("/iniciar-sesion");

    //   props.history.push("/iniciar-sesion");

    /*  const goBack = () => {
         props.history.goBack()
     } */
    useEffect(() => {

        const consultarAPI = async () => {

            const clienteConsulta = await clienteAxios.get(
                `/tableropartido/`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            });
            //(clienteConsulta.data);
            guardarUsuarios(clienteConsulta.data.rows);

        };
        consultarAPI()


    }, [])
    //console.log("Se carga el componente");
    return (
        <div className="container mt-5" align="center">
            <h1 className="animate__animated  animate__flash">Monitor Partido</h1>
            <div className="row">
                <ExportToExcel apiData={usuarios} fileName="usuarios" />

                <div className="col-md-12">

                    <table className="table animate__animated  animate__fadeInLeft ">
                        <thead className="">
                            <tr>
                                <th scope="col">Partido</th>
                                <th scope="col">Codigo Partido</th>
                                <th scope="col">Total viviendas Asignadas</th>
                                <th scope="col">Total viviendas En curso</th>
                                <th scope="col">Total viviendas Sincronizadas</th>
                                <th scope="col">Entrevistas realizadas</th>
                                <th scope="col">Ausencias</th>
                                <th scope="col">Rechazo y No respuesta</th>

                            </tr>
                        </thead>
                        <tbody>

                            {usuarios.map(item => (

                                <tr key={item.email}>
                                    <td className="azul">{item.partido}</td>
                                    <td className="azul">{item.codpart}</td>
                                    <td className="azul">{item.viviendasasignadas}</td>
                                    <td className="verde">{item.viviendasencurso}</td>
                                    <td className="rojo">{item.viviendassincronizadas}</td>
                                    <td className="azul">{item.entrevistas_realizadas}</td>
                                    <td className="rojo">{item.ausencias}</td>
                                    <td className="rojo">{item.rechazoynorespuesta}</td>


                                    {/* <td><img src={`${process.env.PUBLIC_URL}/imagenes/${item.img}`} alt={item.nombre} width="30px" className="img-fluid" /></td> */}
                                </tr>

                            ))}

                        </tbody>

                    </table>

                </div>

            </div>
            <Link to={{ pathname: `/` }} className="btn btn-rojo ml-40  redondo">volver</Link>



        </div>
    );
}

export default Tableropartido;