import React, { useContext } from "react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { CRMContext } from "../../context/CRMContext";
import getUserData from '../../utils/getDataUser';

const Navegacion = (props) => {
  const [auth] = useContext(CRMContext);

  /*   console.log("auth navegacion");
    console.log(auth); */
  //const [token, guardarToken] = useState('');

  /*   useEffect(() => {
      //(localStorage.getItem('token') === null) && localStorage.setItem("token", "")
      guardarToken(getUserData())
      console.log("EFECTOOOOOOOOOOOOOOOOOOOO");
    }, [auth]); */


  //if (localStorage.getItem('token') === "") return null;
  if (localStorage.getItem('token') === "") return null;
  //localStorage.getItem('token') !== "" && localStorage.getItem('token') !== "undefined"))
  //guardarToken(getUserData());
  const token = getUserData();
  //console.log("token");
  //console.log(token);
  return (
    <aside className="sidebar col-3">
      <h2>Panel</h2>
      {token.user.first_name !== undefined ? (
        <>
          <h5>{token.user.first_name.toUpperCase()}</h5>
          <h5> {token.user.id_rol === 3 && "Rol: Coordinador/a General de DPE"}</h5>
          <h5> {token.user.id_rol === 4 && "Rol: Asistente DPE"}</h5>
          <h5> {token.user.id_rol === 5 && "Rol: Coordinador Municipal"}</h5>
          <h5> {token.user.id_rol === 6 && "Rol: Supervisor Municipal"}</h5>
          <h5> {token.user.id_rol === 7 && "Rol: Analista DPE"}</h5>
          <h5>Codigo Partido: {token.user.codpart}</h5>




          <nav className="navegacion">
            {((token.user.id_rol !== 3) && (token.user.id_rol !== 7)) && <Link to={"/viviendas"} className="clientes">
              Consultar y/o asignar Vivienda/s a Encuestador/a
            </Link>}
            {((token.user.id_rol !== 3) && (token.user.id_rol !== 7)) && <Link to={"/viviendasxsegmento"} className="productos">
              Asignar Segmento a Encuestador/a
            </Link>}
            {((token.user.id_rol !== 3) && (token.user.id_rol !== 7)) && <Link to={"/viviendasdesasignar"} className="productos">
              Desasignar Segmento a Encuestador/a
            </Link>}
            {(token.user.id_rol !== 7) &&
              <Link to={"/tablero"} className="pizarra">
                Visualizar avance de operativo según Encuestador/a
              </Link>}
            {(token.user.id_rol !== 7) &&
              <Link to={"/tableropartido"} className="pizarra">
                Visualizar avance de operativo según Partido
              </Link>}
            {(token.user.id_rol !== 7) &&
              <Link to={"/seguimientouser1"} className="seguir">
                Seguimiento de Encuestadores/as
              </Link>}
            {(token.user.id_rol !== 7) &&
              <Link to={"/seguimientouser"} className="seguir">
                Seguimiento de Viviendas por Encuestador/a
              </Link>}
            {((token.user.id_rol === 3) || (token.user.id_rol === 4) || (token.user.id_rol === 7)) && <Link to={"/reportes"} className="list">
              Descarga de reportes de cuestionarios sincronizados
            </Link>}
            {/*    <Link to={"/pedidos"} className="pedidos">
          Pedidos
        </Link> */}
          </nav>
        </>
      ) : null}
    </aside>
  );
};

export default Navegacion;
