import React, { useState, useContext, Fragment } from "react";
import clienteAxios from "../../config/axios";
import Cliente from "./Cliente";
//import Spinner from "../layout/Spinner";

//import rol from '../../utils/nivel';
import { CRMContext } from "../../context/CRMContext";
import { UsuariosContext } from "../../context/UsuariosContext";

const Seguimiento = (props) => {

    const [auth] = useContext(CRMContext);
    const { usuarios } = useContext(UsuariosContext);

    // cliente = state, datosCliente = funcion para guardar el state
    const [clientes, guardarClientes] = useState([]);

    const [busqueda, guardarBusqueda] = useState({
        estadoBusqueda: 'todas',
        email: ""
    });

    // Si el state esta como false
    if (auth.token === "")
        props.history.push("/iniciar-sesion");

    const agregarCliente = (e) => {
        e.preventDefault();
        busqueda.partido !== '' && busqueda.segmento !== '' ? consultarAPI2() : alert("Ambos valores son requeridos!!")
    };
    const handleChange = (e) => {
        guardarBusqueda({
            ...busqueda,
            [e.target.name]: e.target.value
        })
    }
    const consultarAPI2 = async () => {
        try {
            /*    const clientesConsulta = await clienteAxios.get(`/listadofiltrado/${busqueda}`, {
                 headers: {
                   Authorization: `Bearer ${localStorage.getItem("token")}`,
                 }
               }); */
            //console.log("Envio a node");
            // console.log(busqueda);
            const clienteConsulta2 = await clienteAxios.get(`/listadouser/${busqueda.email}/${busqueda.estadoBusqueda}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            });
            // colocar el resultado en el state
            guardarClientes(clienteConsulta2.data.rows);
            //console.log("================");
            // console.log(clientesConsulta.data);
        } catch (error) {
            // Error con authorizacion
            //console.log(error.response);

        }
    };

    return (

        <Fragment>
            <h1 className="animate__animated animate__rubberBand">Seguimiento User</h1>

            <form onSubmit={agregarCliente}>



                <select
                    className="form-control select-css w400"
                    name="email"
                    onChange={handleChange}
                    required
                >
                    <option value="">-- Selecciona usuario --</option>
                    {usuarios.map(usuario => (
                        <option
                            key={usuario.email}
                            value={usuario.email}
                        >{usuario.email}</option>
                    ))}
                </select>

                <select
                    className="form-control select-css w400"
                    name="estadoBusqueda"
                    onChange={handleChange}
                    required
                >
                    <option value="todas">Todas las viviendas</option>
                    <option value="sincronizadas">Sincronizadas</option>
                    <option value="nosincronizadas">No sincronizadas</option>
                </select>

                <input
                    type="submit"
                    className="redondo block btn btn-gris"
                    value="Consultar User"
                /* disabled={ } */
                />



                {/*    <Link to={"/clientes/nuevo"} className="btn btn-verde nvo-cliente">
        <i className="fas fa-plus-circle"></i>
        agregar vivienda
      </Link> */}
            </form>
            <ul className="listado-clientes">
                {clientes.map((cliente) => (
                    <Cliente key={cliente.id_base} cliente={cliente} />

                ))}
            </ul>


            {/*         <div className="mt-105">
                <button className="btn btn-rojo  redondo center" type="button" onClick={goBack}>Volver</button>
                <input
                    type="submit"
                    className="btn btn-azul ml-40 redondo"
                    value="Guardar Cambios"
                    disabled={validarCliente()}
                    required
                />

            </div> */}
        </Fragment >







    );








}

export default Seguimiento;