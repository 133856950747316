import React, { Fragment, useContext } from 'react';

// Routing
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import getUserData from '../src/utils/getDataUser';

/*** Layout */
import Header from './componentes/layout/Header';
import Navegacion from './componentes/layout/Navegacion';


/** Componentes */
import Bienvenida from './componentes/clientes/Bienvenida';
import Clientes from './componentes/clientes/Clientes';
import NuevoCliente from './componentes/clientes/NuevoCliente';
import EditarCliente from './componentes/clientes/EditarCliente';
import ViewSurvey from './componentes/clientes/ViewSurvey';
import Respuestas from './componentes/clientes/Respuestas';
import Individuales from './componentes/clientes/Individuales';
import Individual from './componentes/clientes/Individual';
import Hogares from './componentes/clientes/Hogares';
import Hogar from './componentes/clientes/Hogar';
import viviendasxsegmento from './componentes/clientes/viviendasxsegmento';
import viviendasdesasignar from './componentes/clientes/viviendasdesasignar';
import Tableropartido from './componentes/clientes/Tableropartido';

import Tablero from './componentes/clientes/Tablero';
import Seguimiento from './componentes/clientes/Seguimiento';
import Seguimiento1 from './componentes/clientes/Seguimiento1';
import Reporte from './componentes/clientes/Reporte';

import Pedidos from './componentes/pedidos/Pedidos';
import NuevoPedido from './componentes/pedidos/NuevoPedido';

import Login from './componentes/auth/Login';

import { CRMContext, CRMProvider } from './context/CRMContext';
import UsuariosProvider from './context/UsuariosContext';
import PartidosProvider from './context/PartidosContext';


function App() {

  // utilizar context en el componente
  const [auth, guardarAuth] = useContext(CRMContext);
  const token = getUserData();

  return (
    <Router>
      <Fragment>
        <CRMProvider value={[auth, guardarAuth]}>
          <PartidosProvider >
            <UsuariosProvider >
              <Header />

              <div className="grid contenedor contenido-principal">
                <Navegacion />

                <main className="caja-contenido col-9 ">
                  <Switch>





                    <Route exact path="/" component={Bienvenida} />
                    <Route exact path="/viviendas" component={Clientes} />

                    <Route exact path="/clientes/nuevo" component={NuevoCliente} />
                    <Route exact path="/viviendas/viewencuesta/:id" component={ViewSurvey} />
                    <Route exact path="/viviendas/editar/:id" component={EditarCliente} />
                    <Route exact path="/viviendas/respuestas/" component={Respuestas} />
                    <Route exact path="/viviendas/hogares/" component={Hogares} />
                    <Route exact path="/viviendas/hogares/hogar/:id" component={Hogar} />
                    <Route exact path="/seguimientouser/" component={Seguimiento} />
                    <Route exact path="/seguimientouser1/" component={Seguimiento1} />
                    <Route exact path="/reportes/" component={Reporte} />

                    <Route exact path="/viviendas/hogares/individuales" component={Individuales} />
                    <Route exact path="/viviendas/hogares/individuales/individual/:id" component={Individual} />
                    <Route exact path="/viviendasdesasignar" component={viviendasdesasignar} />
                    <Route exact path="/viviendasxsegmento" component={viviendasxsegmento} />
                    <Route exact path="/tablero" component={Tablero} />
                    <Route exact path="/tableropartido" component={Tableropartido} />
                    

                    <Route exact path="/pedidos" component={Pedidos} />
                    <Route exact path="/pedidos/nuevo/:id" component={NuevoPedido} />


                    <Route exact path="/iniciar-sesion" component={Login} />
                  </Switch>
                </main>
              </div>
            </UsuariosProvider>
          </PartidosProvider>
        </CRMProvider>
      </Fragment>
    </Router>
  )
}

export default App;