import React, { createContext, useState, useEffect, useContext } from 'react';
import clienteAxios from "../config/axios";
import { CRMContext } from "../context/CRMContext";
// Crear el Context
export const PartidosContext = createContext();

// Provider es donde se encuentran las funciones y state
const PartidosProvider = (props) => {

    // crear el state del Context
    const [partidos, guardarpartidos] = useState([]);
    const [partidosall, guardarpartidosall] = useState([]);



    const [auth] = useContext(CRMContext);
    /*     console.log("auth partidosContext");
        console.log(auth);
     */
    // ejecutar el llamado a la api

    useEffect(() => {
        if (auth.auth) {
            const obtenerPartidos = async () => {

                const partidos = await clienteAxios.get(
                    `/partidos/`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                });
                const partidosall = await clienteAxios.get(
                    `/partidosall/`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                });
                guardarpartidosall(partidosall.data.rows);
                guardarpartidos(partidos.data.rows);
            }
            obtenerPartidos();
        }
    }, [auth]);
    //  console.log(partidos);
    return (
        <PartidosContext.Provider
            value={{
                partidos,
                partidosall
            }}
        >
            {props.children}
        </PartidosContext.Provider>
    )
}
export default PartidosProvider;