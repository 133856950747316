import React, { useEffect, useState, useContext, Fragment } from "react";
import clienteAxios from "../../config/axios";
import LoadingSpinner from "../layout/LoadingSpinner";



//import Spinner from "../layout/Spinner";

//import rol from '../../utils/nivel';
import { CRMContext } from "../../context/CRMContext";


import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { PartidosContext } from "../../context/PartidosContext";
const Seguimiento = (props) => {

    const [auth] = useContext(CRMContext);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    // cliente = state, datosCliente = funcion para guardar el state
    const [busqueda, guardarBusqueda] = useState('');
    const [Busquedaselectueda, guardarBusquedaselect] = useState('');

    const [clientes, guardarClientes] = useState([]);
    const [seleccion, guardarSeleccion] = useState('');

    const { partidosall } = useContext(PartidosContext);

    //console.log(partidosall);
    /* const [data, guardarData] = useState([]); */


    // Si el state esta como false
    if (auth.token === "")
        props.history.push("/iniciar-sesion");

    useEffect(() => {

        if (busqueda === '') {
            return
        }
        // guardarData([])
        const callApi = async (busqueda) => {
            try {
                setIsLoading(true)

                let clienteConsulta2
                if (busqueda !== 'individuales') {
                    clienteConsulta2 = await clienteAxios.get(`/consultardata/${busqueda}`, {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("token")}`,
                        },
                    })
                } else {
                    clienteConsulta2 = await clienteAxios.get(`/consultardata/${busqueda}/${seleccion.partido}`, {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("token")}`,
                        },
                    })
                }
                let resultado = await clienteConsulta2.data.rows
                /* guardarData(resultado) */
                exportToCSV(resultado, busqueda);
                setIsLoading(false)

            } catch (error) {
                // Error con authorizacion
                // console.log(error.response);

            }
        };
        callApi(busqueda)


    }, [busqueda])
    const guardarSelect1 = (e) => {
        //console.log(e.target.value);
        guardarBusquedaselect(
            {
                ...busqueda,
                [e.target.name]: e.target.value
            })
        //console.log(busqueda);



    }

    const HandleClick = (value) => {
        //e.preventDefault()
        // console.log("hizo click");
        //console.log(e);
        // console.log(value);

        guardarBusqueda(value)
        //  console.log("data");
        //console.log(data);
        //setTimeout(() => { console.log(data) }, 6000)
    }

    const HandleClick2 = (value) => {
        //e.preventDefault()

        //console.log(e);
        guardarSeleccion(Busquedaselectueda)
        guardarBusqueda(value)
        //  console.log("data");
        //console.log(data);
        //setTimeout(() => { console.log(data) }, 6000)
    }
    const consultarAPI2 = async () => {
        try {
            /*    const clientesConsulta = await clienteAxios.get(`/listadofiltrado/${busqueda}`, {
                 headers: {
                   Authorization: `Bearer ${localStorage.getItem("token")}`,
                 }
               }); */
            //console.log("Envio a node");
            // console.log(busqueda);
            const clienteConsulta2 = await clienteAxios.get(`/listadofiltrado/${busqueda.partido}/${busqueda.segmento}/${busqueda.estadoBusqueda}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            });
            // colocar el resultado en el state
            guardarClientes(clienteConsulta2.data.rows);
            //console.log("================");
            // console.log(clientesConsulta.data);
        } catch (error) {
            // Error con authorizacion
            // console.log(error.response);
            if ((error.response.status = 500)) {
                props.history.push("/iniciar-sesion");
            }
        }
    };

    const agregarCliente = (e) => {
        e.preventDefault();
        busqueda.partido !== '' && busqueda.segmento !== '' ? consultarAPI2() : alert("Ambos valores son requeridos!!")
    };


    const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const exportToCSV = (apiData, fileName) => {
        const ws = XLSX.utils.json_to_sheet(apiData);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    };

    return (

        <Fragment>
            <h1 className="center animate__animated animate__rubberBand">Reportes de operativo</h1>
            {/* <button name="caracteristicas_hogar" className="redondo bggris btn mb-40 " onClick={(e) => HandleClick("caracteristicas_hogar")}>Descargar Hogares</button> */}
            <button name="hogares" className="redondo bggris btn mb-40 " onClick={() => HandleClick("hogares")} disabled={isLoading}>Descargar Hogares</button>
            <hr />
            <h1 className="animate__animated animate__bounce">Descargar de Individuales:</h1>


            <select
                className="form-control select-css w400"
                name="partido"
                onChange={guardarSelect1}
                required
            >
                <option value="">--Selecciona partido--</option>
                {partidosall.map(partido => (
                    <option
                        key={partido.codpart}
                        value={partido.codpart}
                    >{partido.partido.toUpperCase()}</option>
                ))}
            </select>

            {isLoading && <LoadingSpinner />}
            {errorMessage && <div className="error">{errorMessage}</div>}

            {/* <button name="hogares" className="redondo bggris btn mb-40 " onClick={() => HandleClick("hogares")}>Descargar Hogares</button>*/}
            <button name="individuales" className="redondo bggris btn mb-40 " onClick={() => HandleClick2("individuales")} disabled={isLoading}>Descargar Individuales</button>

        </Fragment >
    );








}

export default Seguimiento;