import React, { Fragment, useState, useEffect, useContext } from "react";
import Swal from "sweetalert2";
//import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import clienteAxios from "../../config/axios";
import { CRMContext } from "../../context/CRMContext";
import { UsuariosContext } from "../../context/UsuariosContext";

function EditarCliente(props) {
  // obtener el ID
  const [auth] = useContext(CRMContext);
  const id_base = props.match.params.id;
  const { usuarios } = useContext(UsuariosContext);
  // cliente = state, datosCliente = funcion para guardar el state

  const [busqueda, guardarBusqueda] = useState({
    nombre: ''
  });
  const [cliente, datosCliente] = useState({

  });
  const guardar = e => {
    //console.log(e.target.value);
    guardarBusqueda({

      "email": e.target.value,
      "id_base": id_base
    })
  }
  const goBack = () => {
    props.history.goBack()
  }
  // Query a la API
  const consultarAPI = async () => {
    const clienteConsulta = await clienteAxios.get(
      `/viviendas/${id_base}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    //console.log(auth.token);
    //console.log("Aca veo");
    //console.log(clienteConsulta.data);
    // colocar en el state
    datosCliente(clienteConsulta.data);
  };

  // useEffect, cuando el componente carga
  useEffect(() => {
    if (auth.token === "")
      return null
    consultarAPI();
  }, []);

  // leer los datos del formulario
  /*   const actualizarState = (e) => {
    // Almacenar lo que el usuario escribe en el state
      const target = e.target;
      const value = target.type === "checkbox" ? target.checked : target.value;
      const name = target.name;
      datosCliente({
        // obtener una copia del state actual
        ...cliente,
        [name]: value,
      });
    };
   */
  // Envia una petición por axios para actualizar el cliente
  const actualizarCliente = (e) => {
    e.preventDefault();
    //console.log("busqueda");
    //console.log(busqueda);
    // enviar petición por axios
    if (busqueda.nombre === '') {
      redireccion()
      //("vacio me voy");
    }

    clienteAxios.put(`/viviendas/`, busqueda, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }).then((res) => {
      if (res.data.rowCount[0] === 1) {
        Swal.fire({
          type: "error",
          title: "Hubo un error",
          text: "Ese cliente ya esta registrado",
        });
      } else {
        Swal.fire("Correcto", "Se actualizó Correctamente", "success");
      }
      props.history.push("/");
    });
  };
  //console.log(usuarios);
  // Validar el formulario
  const redireccion = (e) => {
    e.preventDefault()
   // console.log("click");
    props.history.push("/");
  }
  const validarCliente = () => {
    // Destructuring
    /* const { first_name, last_name, email, password, active } = cliente;
  */
    // revisar que las propiedades del state tengan contenido
    /*  let valido = !first_name || !last_name || !email || !password || !active; */
    //console.log("usuarios")
    //console.log(usuarios)
    ;    // return true o false
    ;    // return true o false
    // return valido;
  };

  return (
    <Fragment>
      <h2>Asignar Vivienda a User</h2>

      <form onSubmit={actualizarCliente}>
        <legend>Datos de la vivienda</legend>
        <div className="negrita campo2 grisOscuro">
          <label>Usuario Actual para esta vivienda:</label>
          <label className="rojo ml-18">{cliente.email}</label>
        </div>


        <p className="campo"><label>upm:</label> {cliente.upm}</p>
        <p className="campo"><label>codpart:</label> {cliente.codpart}</p>
        <p className="campo"><label>Codigo de lado:</label> {cliente.clado}</p>
        <p className="campo"><label>nviv:</label> {cliente.nviv}</p>
        <p className="campo"><label>nviv_dec:</label> {cliente.nviv_dec}</p>
        <p className="campo"><label>codigo_calle:</label> {cliente.codigo_calle}</p>
        <p className="campo"><label>calle:</label> {cliente.calle}</p>
        <p className="campo"><label>numero:</label> {cliente.numero}</p>
        <p className="campo"><label>edificio:</label> {cliente.edificio}</p>
        <p className="campo"><label>entrada:</label> {cliente.entrada}</p>
        <p className="campo"><label>piso:</label> {cliente.piso}</p>
        <p className="campo"><label>dpto_edif:</label> {cliente.dpto_edif}</p>
        <p className="campo"><label>habitacion:</label> {cliente.habitacion}</p>
        <p className="campo"><label>tipo_viv:</label> {cliente.tipo_viv}</p>
        <p className="campo"><label>descripcion:</label> {cliente.descripcion}</p>
        <p className="campo"><label>segmento:</label> {cliente.segmento}</p>

        <p className="campo"><label className="negrita">Estado:  </label>{cliente.censo === null ? <label className="verde negrita">No sincronizada </label> : <label className="rojo negrita">Sincronizada</label>}</p>
        <legend className="">Seleccione el nuevo usuario para la vivienda en cuestion</legend>
        <div className="campo">
          <label>Email:</label>
          {/* <select
            type="email"
            placeholder="Email User"
            name="email"
            onChange={actualizarState}
            value={cliente.email}
          /> */}
          <select
            className="form-control select-css"
            name="categoria"
            onChange={guardar}
          >
            <option value="">-- Selecciona usuario --</option>
            {usuarios.map(usuario => (
              <option
                key={usuario.email}
                value={usuario.email}
              >{usuario.email}</option>
            ))}
          </select>
        </div>
        <div className="enviar">
          {/* <Link to="/" className="btn btn-rojo  redondo">Volver</Link> */}
          <button className="btn btn-rojo ml-40  redondo" type="button" onClick={goBack}>Volver</button>
          {cliente.censo === null ? <input
            type="submit"
            className="btn btn-azul ml-40 redondo"
            value="Guardar Cambios"
            disabled={validarCliente()}
          /> : null}

        </div>
      </form>
    </Fragment>
  );
}

// HOC, es una función que toma un componente y retorna un nuevo componente
export default withRouter(EditarCliente);
