import React, { Fragment } from 'react';
import { Link } from "react-router-dom";



const Hogar = (props) => {
   // console.log(props.history)
    //console.log(props.location.state.map((item) => console.log(item)));
    const goBack = () => {
        props.history.goBack()
    }

    return (

        <Fragment>
            <h2>Hogar: {props.location.state.id} </h2>
            {/* {props.location.state.map((item) => <div className="enviar">
                <Link to={{ pathname: `/viviendas/hogar/${item}`, state: props.location.state }} className="btn btn-azul ml-40y">Hogar:{item.id}</Link>
            </div>)}
 */}
            {(props.location.state.respuestas !== undefined ?

                Object.keys(props.location.state.respuestas).map(
                    (item) => <p className="campo"><label className="negrita">{item}:   </label>{
                        (typeof (props.location.state.respuestas[item]) != 'object') ? props.location.state.respuestas[item]
                            : JSON.stringify(props.location.state.respuestas[item])

                    } </p>)

                : null)
            }
            <div className="mt-40">

                <button className="btn btn-rojo redondo ml-40 " type="button" onClick={goBack}>
                    Volver
                </button>
                <Link to={{ pathname: `/viviendas/hogares/individuales`, state: props.location.state.componentes }} className="btn btn-azul ml-40 redondo">Ir a Individuales</Link>
            </div>
        </Fragment>
    );
}

export default Hogar;