import React, { Fragment, useState, useEffect, useContext } from "react";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import clienteAxios from "../../config/axios";
import { CRMContext } from "../../context/CRMContext";
/*import { UsuariosContext } from "../../context/UsuariosContext";
 */

function ViewSurvey(props) {
    // obtener el ID
    const id_base = props.match.params.id;
    const [auth] = useContext(CRMContext);
    /*  const { usuarios, guardarUsuariosh } = useContext(UsuariosContext); */
    // cliente = state, datosCliente = funcion para guardar el state

    const [busqueda] = useState({
        nombre: ''
    });
    const [cliente, datosCliente] = useState({
    });
    const goBack = () => {
        props.history.goBack()
    }
    /*    const guardar = e => {
           //console.log(e.target.value);
           guardarBusqueda({
   
               "email": e.target.value,
               "id_base": id_base
           })
       }
    */
    // Query a la API
    const consultarAPI = async () => {
        const clienteConsulta = await clienteAxios.get(
            `/viviendas/${id_base}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        });
        //console.log(auth.token);
        //console.log("Aca veo");
        //console.log(clienteConsulta.data);
        // colocar en el state
        datosCliente(clienteConsulta.data);
    };

    // useEffect, cuando el componente carga
    // console.log(auth.token);
    if (auth.token === "")
        return null
    useEffect(() => {
        consultarAPI();
    }, []);

    // leer los datos del formulario
    /*    const actualizarState = (e) => {
           // Almacenar lo que el usuario escribe en el state
           const target = e.target;
           const value = target.type === "checkbox" ? target.checked : target.value;
           const name = target.name;
           datosCliente({
               // obtener una copia del state actual
               ...cliente,
               [name]: value,
           });
       };
    */
    // Envia una petición por axios para actualizar el cliente
    const actualizarCliente = (e) => {
        e.preventDefault();
        /*         console.log("busqueda");
                console.log(busqueda); */
        // enviar petición por axios
        if (busqueda.nombre === '') {
            redireccion()
            // console.log("vacio me voy");
        }

        clienteAxios.put(`/viviendas/`, busqueda, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        }).then((res) => {
            if (res.data.rowCount[0] === 1) {
                Swal.fire({
                    type: "error",
                    title: "Hubo un error",
                    text: "Ese cliente ya esta registrado",
                });
            } else {
                Swal.fire("Correcto", "Se actualizó Correctamente", "success");
            }
            props.history.push("/");
        });
    };
    //console.log(usuarios);
    // Validar el formulario
    const redireccion = (e) => {
        e.preventDefault()
        //console.log("click");
        props.history.push("/");
    }


    return (
        <Fragment>

            <form onSubmit={actualizarCliente}>
                <legend>Datos de la vivienda</legend>
                <div className="campo">
                    <label>Usuario Actual para esta vivienda:</label>
                    {cliente.email}
                </div>
                {(cliente.censo !== undefined ?

                    Object.keys(cliente.censo).map(
                        (item) => <p className="campo"><label key={item.id}>{item}:</label>{
                            (typeof (cliente.censo[item]) != 'object') ? cliente.censo[item]
                                : <div className="enviar">
                                    <Link to={{ pathname: `/viviendas/${item}/`, state: cliente.censo[item] }} className="btn btn-azul ml-40 redondo">Ir a {item}</Link>
                                </div>

                        } </p>)

                    : null)
                }
                {/* <p className="campo"><label>upm:</label> {cliente.upm}</p>
                <p className="campo"><label>codpart:</label> {cliente.codpart}</p>
                <p className="campo"><label>Codigo de lado:</label> {cliente.clado}</p>
                <p className="campo"><label>nviv:</label> {cliente.nviv}</p>
                <p className="campo"><label>nviv_dec:</label> {cliente.nviv_dec}</p>
                <p className="campo"><label>codigo_calle:</label> {cliente.codigo_calle}</p>
                <p className="campo"><label>calle:</label> {cliente.calle}</p>
                <p className="campo"><label>numero:</label> {cliente.numero}</p>
                <p className="campo"><label>edificio:</label> {cliente.edificio}</p>
                <p className="campo"><label>entrada:</label> {cliente.entrada}</p>
                <p className="campo"><label>piso:</label> {cliente.piso}</p>
                <p className="campo"><label>dpto_edif:</label> {cliente.dpto_edif}</p>
                <p className="campo"><label>habitacion:</label> {cliente.habitacion}</p>
                <p className="campo"><label>tipo_viv:</label> {cliente.tipo_viv}</p>
                <p className="campo"><label>descripcion:</label> {cliente.descripcion}</p>
                <p className="campo"><label>segmento:</label> {cliente.segmento}</p>
 */}
                {/*    <div className="">
                    <Link to="/" className="btn btn-rojo ml-40 redondo">Volver</Link>
                </div> */}
                <button className="btn btn-rojo ml-40 mt-100 redondo" type="button" onClick={goBack}>Volver</button>
            </form>
        </Fragment>
    );
}

// HOC, es una función que toma un componente y retorna un nuevo componente
export default withRouter(ViewSurvey);
