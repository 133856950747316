import React, { useState, useEffect, useContext } from "react";
import Swal from "sweetalert2";
import { withRouter } from "react-router-dom";
import clienteAxios from "../../config/axios";

// Context
import { CRMContext } from "../../context/CRMContext";

function Login(props) {
  // Auth y token
  const [auth, guardarAuth] = useContext(CRMContext);

  // State con los datos del formulario
  const [credenciales, guardarCredenciales] = useState({});

  useEffect(() => {

    localStorage.getItem("token") === null && localStorage.setItem("token", "")
    if (localStorage.getItem("token") !== "" && auth.auth === false) {
      const token = localStorage.getItem("token");

      guardarAuth({
        token,
        auth: true,
      });
      props.history.push("/");
    }

  }, []);



  // iniciar sesión en el servidor
  const iniciarSesion = async (e) => {
    e.preventDefault();

    // autenticar al usuario

    try {
      const respuesta = await clienteAxios.post(
        "/api/login_conbycriptgestor",
        credenciales
      );

      // extraer el token y colocarlo en localstorage
      const { token } = respuesta.data;
      //console.log("respuesta.data");
      //console.log(respuesta.data);
      if (respuesta.data === "Password invalido" || respuesta.data === "EMAIL INVALIDO"
      ) {
        Swal.fire({
          type: "error",
          title: "Hubo un error",
          /*           text: respuesta.data, */
          text: "Error,consultar con administrador"
        });
        return
      }


      localStorage.setItem("token", token);

      // colocarlo en el state
      guardarAuth({
        token,
        auth: true,
      });

      // alerta
      Swal.fire("Login Correcto", "Has iniciado Sesión", "success");
      // redireccionar
      props.history.push("/");
    } catch (error) {
      // console.log(error);
      Swal.fire({
        type: "error",
        title: "Hubo un error",
        text: error.response.data.mensaje,
      });
    }
  };

  // almacenar lo que el usuario escribe en el state
  const leerDatos = (e) => {
    guardarCredenciales({
      ...credenciales,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div className="login">
      <h2>Iniciar Sesión</h2>

      <div className="contenedor-formulario">
        <form onSubmit={iniciarSesion}>
          <div className="campo">
            <label>Email</label>
            <input
              type="text"
              name="name"
              placeholder="Email para Iniciar Sesión"
              required
              onChange={leerDatos}
            />
          </div>

          <div className="campo">
            <label>Password</label>
            <input
              type="password"
              name="passwd"
              placeholder="Password para Iniciar Sesión"
              required
              onChange={leerDatos}
            />
          </div>

          <input
            type="submit"
            value="Iniciar Sesión"
            className="btn btn-gris btn-block"
          />
        </form>
      </div>
    </div>
  );
}

export default withRouter(Login);
