import React, { Fragment, useState, useEffect, useContext } from "react";
import Swal from "sweetalert2";
//import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import clienteAxios from "../../config/axios";
import { CRMContext } from "../../context/CRMContext";
import { UsuariosContext } from "../../context/UsuariosContext";
import { PartidosContext } from "../../context/PartidosContext";
//import Seleccion from "../clientes/Seleccion";

function viviendasxsegmento(props) {
    // obtener el ID
    //const id_base = props.match.params.id;
    const [auth] = useContext(CRMContext);
    const { usuarios } = useContext(UsuariosContext);
    const { partidos } = useContext(PartidosContext);

    // cliente = state, datosCliente = funcion para guardar el state

    const [busqueda, guardarBusqueda] = useState({
        partido: "",
        segmento: ""
    });

    const [partidosegmento, guardarpartidosegmento] = useState({});


    // Si el state esta como false
    if (!auth.auth) {
        props.history.push("/iniciar-sesion");
    }

    const guardar = e => {
        //console.log(e.target.value);
        guardarBusqueda(
            {
                ...busqueda,
                [e.target.name]: e.target.value
            })
    }

    useEffect(() => {
        if (busqueda !== '') {
            const consultarAPI = async () => {
                if (busqueda.partido === '') return null
                const clienteConsulta = await clienteAxios.get(
                    `/busquedapartsegAll/${busqueda.partido}/`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                });
                guardarpartidosegmento(clienteConsulta.data);

            };
            consultarAPI()/*  */
        }
    }, [busqueda]);

    const guardarSelect1 = (e) => {
        // console.log(e.target.value);
        guardarBusqueda(
            {
                ...busqueda,
                [e.target.name]: e.target.value
            })
        // console.log("Llamamos a consultar Api");



    }


    const goBack = () => {
        props.history.goBack()
    }
    // Query a la API

    // leer los datos del formulario
    /*   const actualizarState = (e) => {
          // Almacenar lo que el usuario escribe en el state
          const target = e.target;
          const value = target.type === "checkbox" ? target.checked : target.value;
          const name = target.name;
          datosCliente({
              // obtener una copia del state actual
              ...cliente,
              [name]: value,
          });
      };
   */
    // Envia una petición por axios para actualizar el cliente
    const actualizarCliente = (e) => {
        e.preventDefault();
        if (busqueda.segmento === '' || busqueda.partido === '') {
            //console.log("vacio me voy");
            redireccion()
        }

        clienteAxios.put(`/desasignarsegmento/`, busqueda, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        }).then((res) => {
            if (res.data.rowCount[0] === 1) {
                Swal.fire({
                    type: "error",
                    title: "Hubo un error",
                    text: "Error al asignar segmento",
                });
            } else {
                Swal.fire("Correcto", "Se actualizó Correctamente", "success");
            }
            props.history.push("/");
        });
    };
    //console.log(usuarios);
    // Validar el formulario
    const redireccion = (e) => {
        e.preventDefault()
        //console.log("click");
        props.history.push("/");
    }
    const validarCliente = () => {
        // Destructuring
        /* const { first_name, last_name, email, password, active } = cliente;
      */
        // revisar que las propiedades del state tengan contenido
        /*  let valido = !first_name || !last_name || !email || !password || !active; */
        //console.log("usuarios")
        //console.log(usuarios)
        ;    // return true o false
        ;    // return true o false
        // return valido;
    };

    return (
        <Fragment>

            <h3 className="rojo animate__animated  animate__flash">Se desasigna el segmento, pero las viviendas que ya fueron sincronizadas no se desasignarán. Por lo que, si alguna vivienda fue sincronizada en el segmento que selecciona, la asignación de todo el segmento tendrá que hacerse de forma manual desde el botón Viviendas del panel de navegación</h3>
            <form onSubmit={actualizarCliente}>


                <div className="campo">
                    <label>Partido:</label>

                    <select
                        className="form-control select-css m0"
                        name="partido"
                        onChange={guardarSelect1}
                    >
                        <option value="">-- Selecciona partido --</option>
                        {partidos.map(partido => (
                            <option
                                key={partido.partido}
                                value={partido.partido}
                            >{partido.partido}</option>
                        ))}
                    </select>
                </div>
                <div className="campo">
                    <label>Segmento:</label>

                    <select
                        className="form-control select-css m0"
                        name="segmento"
                        onChange={guardar}
                        required
                    >
                        <option value="">-- Selecciona segmento --</option>
                        {Object.keys(partidosegmento).length !== 0 ? partidosegmento.rows.map(segmento => (
                            <option
                                key={segmento.segmento}
                                value={segmento.segmento}
                            >{segmento.segmento}</option>
                        )) : null}
                    </select>
                </div>

                <div className="mt-105">
                    {/* <Link to="/" className="btn btn-rojo  redondo">Volver</Link> */}
                    <button className="btn btn-rojo  redondo center" type="button" onClick={goBack}>Volver</button>
                    <input
                        type="submit"
                        className="btn btn-azul ml-40 redondo"
                        value="Guardar Cambios"
                        disabled={validarCliente()}
                        required
                    />

                </div>
            </form>
        </Fragment>
    );
}

// HOC, es una función que toma un componente y retorna un nuevo componente
export default withRouter(viviendasxsegmento);
