import React, { Fragment } from 'react';
//import { Link } from "react-router-dom";



const Respuestas = (props) => {
  /*   console.log(props.location.state)
    // *///console.log(Object.keys(props.location.state).map((item) => console.log(item)));
    const goBack = () => {
        props.history.goBack()
    }

    return (

        <Fragment>
            <h2>Respuestas del Familiar:  </h2>
            {/* {props.location.state.map((item) => <div className="enviar">
                <Link to={{ pathname: `/viviendas/hogar/${item}`, state: props.location.state }} className="btn btn-azul ml-40y">Hogar:{item.id}</Link>
            </div>)}
 */}
            {(props.location.state !== undefined ?

                Object.keys(props.location.state).map(
                    (item) => <p className="campo"><label className="negrita">{item}:  </label>{props.location.state[item]}
                    </p>)

                : null)
            }
            <div className="mt-40">

                <button className="btn btn-rojo redondo ml-40 " type="button" onClick={goBack}>
                    Volver
                </button>

            </div>
        </Fragment>
    );
}

export default Respuestas;