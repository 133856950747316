import React, { Fragment } from 'react';
//import { Link } from "react-router-dom";
const Individual = (props) => {
    // console.log(props.location.state)
    //console.log(props.location.state.map((item) => console.log(item)));
    const goBack = () => {
        props.history.goBack()
    }
    return (
        <Fragment>

            <h2>Individual: {props.location.state.id} </h2>
            {/* {props.location.state.map((item) => <div className="enviar">
                <Link to={{ pathname: `/viviendas/hogar/${item}`, state: props.location.state }} className="btn btn-azul ml-40y">Hogar:{item.id}</Link>
            </div>)}
 */}
            {(props.location.state.respuestas !== undefined ?

                Object.keys(props.location.state.respuestas).map(
                    (item) => <p className="campo"><label className="negrita">{item}:   </label>{
                        (typeof (props.location.state.respuestas[item]) != 'object') ? props.location.state.respuestas[item]
                            : JSON.stringify(props.location.state.respuestas[item])

                    } </p>)

                : null)
            }
            <button className="btn btn-rojo redondo mt-40 ml-40" type="button" onClick={goBack}>Volver</button>
        </Fragment>
    );
}

export default Individual;