import React, { useState, useContext, Fragment } from "react";
import clienteAxios from "../../config/axios";

//import Spinner from "../layout/Spinner";
import { ExportToExcel } from './ExportToExcel'

import { Link } from "react-router-dom";


//import rol from '../../utils/nivel';
import { CRMContext } from "../../context/CRMContext";
import { UsuariosContext } from "../../context/UsuariosContext";

const Seguimiento = (props) => {

    const [auth] = useContext(CRMContext);
    const { usuarios } = useContext(UsuariosContext);

    // cliente = state, datosCliente = funcion para guardar el state
    const [clientes, guardarClientes] = useState([]);

    const [busqueda, guardarBusqueda] = useState({
        estadoBusqueda: 'todas',
        email: ""
    });

    // Si el state esta como false
    if (auth.token === "")
        props.history.push("/iniciar-sesion");

    const agregarCliente = (e) => {
        e.preventDefault();
        busqueda.partido !== '' && busqueda.segmento !== '' ? consultarAPI2() : alert("Ambos valores son requeridos!!")
    };
    const handleChange = (e) => {
        guardarBusqueda({

            [e.target.name]: e.target.value
        })
    }
    const consultarAPI2 = async () => {
        try {
            /*    const clientesConsulta = await clienteAxios.get(`/listadofiltrado/${busqueda}`, {
                 headers: {
                   Authorization: `Bearer ${localStorage.getItem("token")}`,
                 }
               }); */
            //console.log("Envio a node");
            // console.log(busqueda);
            const clienteConsulta2 = await clienteAxios.get(`/listadouserparaseguimiento/${busqueda.email}/todas`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            });
            // colocar el resultado en el state
            //console.log(clienteConsulta2.data.rows);
            guardarClientes(clienteConsulta2.data.rows);

        } catch (error) {
            // Error con authorizacion
            // console.log(error.response);

        }
    };
    if (clientes.length !== 0) {

        //console.log(clientes[0].email);
        /*        console.log("aca");
               console.log(clientes[0].clado + clientes[0].nviv + clientes[0].nviv_dec);
               console.log(typeof (clientes[0].clado));
               console.log(typeof (clientes[0].nviv));
               console.log(typeof (clientes[0].nviv_dec)); */
    }
    const redefinirClientes = () => {

        /*   clientes.codpart,
      
          clientes.upm,
          clientes.segmento,
          clientes.tipo_viv,
          clientes.fraccion,
          clientes.radio,
          clientes.manzana,
          clientes.lado,
          clientes.nviv,
          {/* <clientes.nviv_dec
      
      {item.censo === null ? <td className="azul">Asignada</td> : <td className="rojo">Sincronizada</td>} */
        //console.log(clientes.filter((item) => item = 'upm'));
        //console.log(clientes);
        var clientes2 = [];
        clientes.map(item => (
            clientes2.push({
                "Codigo Partido": item.codpart,
                "Upm": item.upm,
                "Segmentp": item.segmento,
                "Tipo vivienda": item.tipo_viv,
                "Fraccion": item.fraccion,
                "Radio": item.radio,
                "Manzana": item.manzana,
                "Lado": item.lado,
                "Nviv": item.nviv,
                "Nviv Decimal": item.nviv_dec,
                "Estado": item.censo === null ? "Asignada" : "Sincronizada",
                "telefono": item.telefono,
                "entrevista_realizada": item.entrevista_realizada,
                "causa": item.causa,
            }
            )


        ))
        //console.log(clientes2);
        return clientes2
    }








    return (

        <Fragment>
            <h1 className="animate__animated animate__rubberBand">Seguimiento User</h1>

            <form onSubmit={agregarCliente}>



                <select
                    className="form-control select-css w400"
                    name="email"
                    onChange={handleChange}
                    required
                >
                    <option value="">-- Selecciona usuario --</option>
                    {usuarios.map(usuario => (
                        <option
                            key={usuario.email}
                            value={usuario.email}
                        >{usuario.email}</option>
                    ))}
                </select>

                {/*    <select
                    className="form-control select-css w400"
                    name="estadoBusqueda"
                    onChange={handleChange}
                    required
                >
                    <option value="todas">Todas las viviendas</option>
                    <option value="sincronizadas">Sincronizadas</option>
                    <option value="nosincronizadas">No sincronizadas</option>
                </select> */}

                <input
                    type="submit"
                    className="redondo block btn btn-gris"
                    value="Consultar User"
                /* disabled={ } */
                />



                {/*    <Link to={"/clientes/nuevo"} className="btn btn-verde nvo-cliente">
        <i className="fas fa-plus-circle"></i>
        agregar vivienda
      </Link> */}
            </form>

            <div className="container mt-5" align="center">
                <h1 className="animate__animated  animate__flash">Seguimiento a {clientes.length !== 0 ? clientes[0].email : "..."}</h1>
                <div className="row">
                    <ExportToExcel apiData={redefinirClientes()



                    } fileName="user" />

                    <div className="table-wrapper   ">

                        <table className="table animate__animated  animate__fadeInLeft ">
                            <thead className="">
                                <tr>
                                    <th scope="col">Partido</th>
                                    <th scope="col">Codpart</th>

                                    <th scope="col">UPM</th>
                                    <th scope="col">Segmento</th>
                                    <th scope="col">Tipo_Viv</th>

                                    <th scope="col">fraccion</th>
                                    <th scope="col">radio</th>
                                    <th scope="col">manzana</th>
                                    <th scope="col">lado</th>
                                    <th scope="col">nviv</th>
                                    <th scope="col">nviv_dec</th>
                                    <th scope="col">Estado</th>
                                    <th scope="col">telefono</th>
                                    <th scope="col">Entrevista Realizada</th>
                                    <th scope="col">causa  No Respuesta</th>
                                </tr>
                            </thead>
                            <tbody>

                                {clientes.map(item => (

                                    <tr key={item.clado + item.nviv + item.nviv_dec}>
                                        <td>{item.partido}</td>
                                        <td className="azul">{item.codpart}</td>

                                        <td className="azul">{item.upm}</td>
                                        <td className="azul">{item.segmento}</td>
                                        <td className="azul">{item.tipo_viv}</td>
                                        <td className="azul">{item.fraccion}</td>
                                        <td className="azul">{item.radio}</td>
                                        <td className="azul">{item.manzana}</td>
                                        <td className="azul">{item.lado}</td>
                                        <td className="azul">{item.nviv}</td>
                                        <td className="azul">{item.nviv_dec}</td>

                                        {item.censo === null ? <td className="azul">Asignada</td> : <td className="rojo">Sincronizada</td>}
                                        <td className="azul">{item.telefono}</td>
                                        {/* <td><img src={`${process.env.PUBLIC_URL}/imagenes/${item.img}`} alt={item.nombre} width="30px" className="img-fluid" /></td> */}
                                        <td className="azul">{
                                            (item.entrevista_realizada === '1' && "Si") ||
                                            (item.entrevista_realizada === '2' && "No")
                                        }</td>
                                        <td className="azul">{

                                            (item.causa === '1' && "Deshabitada") ||
                                            (item.causa === '2' && "Fin de semana") ||
                                            (item.causa === '3' && "Construcción") ||
                                            (item.causa === '4' && "Usada como establecimiento") ||
                                            (item.causa === '5' && "Ausencia") ||
                                            (item.causa === '6' && "Rechazo") ||
                                            (item.causa === '7' && "Otras causas")




                                        } </td>
                                    </tr>

                                ))}

                            </tbody>

                        </table>

                    </div>

                </div>
                {/*                 <Link to={{ pathname: `/` }} className="btn btn-rojo ml-40  redondo">volver</Link> */}



            </div>

            {/*         <div className="mt-105">
                <button className="btn btn-rojo  redondo center" type="button" onClick={goBack}>Volver</button>
                <input
                    type="submit"
                    className="btn btn-azul ml-40 redondo"
                    value="Guardar Cambios"
                    disabled={validarCliente()}
                    required
                />

            </div> */}
        </Fragment >







    );








}

export default Seguimiento;