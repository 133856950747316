import React from "react";
import { Link } from "react-router-dom";
import getUserData from '../../utils/getDataUser';

/* import Swal from "sweetalert2";
import clienteAxios from "../../config/axios";
 */
function Cliente({ cliente }) {
  // extraer los valores
  const { email, id_base, upm, codpart, partido, localidad, nviv, nviv_dec, codigo_calle, calle, numero, edificio, entrada, piso, dpto_edif, habitacion, tipo_viv, descripcion, segmento, id_user, clado, censo } = cliente;
  //console.log(cliente);
  // Eliminar cliente
  /*   const eliminarCliente = (id_user) => {
      Swal.fire({
        title: "¿Estas seguro?",
        text: "Un Usuario eliminado no se puede recuperar",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, eliminar!",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.value) {
          // Llamado a axios
          clienteAxios.delete(`/usuarios/${id_user}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }).then((res) => {
            Swal.fire("Eliminado", res.data.mensaje, "success");
          });
        }
      });
    }; */
  // console.log("censo");
  ///console.log(typeof (censo));
  //console.log(censo);
  const token = getUserData();

  return (
    <li className="cliente animate__animated  animate__fadeInDownBig">
      <div className="info-cliente ">

        <p className="first_namee"><label className="negrita">Partido:</label> {partido.toUpperCase()} <label className="negrita"> Localidad: </label>{localidad.toUpperCase()}</p>
        <p className="first_namee"><label className="negrita">dominio:</label> --</p>
        <p className="first_namee"><label className="negrita">upm:</label> {upm}</p>
        <p className="first_namee"><label className="negrita">codpart:</label> {codpart}</p>
        <p className="first_namee"><label className="negrita">Codigo </label>de lado: {clado}</p>
        <p className="first_namee"><label className="negrita">nviv:</label> {nviv}</p>
        <p className="first_namee"><label className="negrita">nviv_dec:</label> {nviv_dec}</p>
        <p className="first_namee"><label className="negrita">codigo_calle:</label> {codigo_calle}</p>
        <p className="first_namee"><label className="negrita">calle:</label> {calle}</p>
        <p className="first_namee"><label className="negrita">numero:</label> {numero}</p>
        <p className="first_namee"><label className="negrita">edificio:</label> {edificio}</p>
        <p className="first_namee"><label className="negrita">entrada:</label> {entrada}</p>
        <p className="first_namee"><label className="negrita">piso:</label> {piso}</p>
        <p className="first_namee"><label className="negrita">dpto_edif:</label> {dpto_edif}</p>
        <p className="first_namee"><label className="negrita">habitacion:</label> {habitacion}</p>
        <p className="first_namee"><label className="negrita">tipo_viv:</label> {tipo_viv}</p>
        <p className="first_namee"><label className="negrita">descripcion:</label> {descripcion}</p>
        <p className="first_namee"><label className="negrita">segmento:</label> {segmento}</p>
        <p className="first_namee"><label className="negrita">id_base:</label> {id_base}</p>
        <p className="first_namee"><label className="negrita">id_user:</label> {id_user} {(email == null) ? "no asignado" : email}</p>
        <p className="first_namee"><label className="negrita">Estado </label>: {censo === null ? <label className="">Encuesta Sin sincronizar </label> : <label className="rojo negrita">Encuesta sincronizada</label>}</p>



        {/* <p className="first_namee"><label>id_base:</label> {id_base}</p>
        <p className="first_namee">codloc: {codloc}</p>
        <p className="first_namee">fraccion: {fraccion}</p>
        <p className="first_namee">radio: {radio}</p>
        <p className="first_namee">manzana: {manzana}</p>
        <p className="first_namee">lado: {lado}</p>
        {/* <p className="img">{img}</p> */}


        {/* <p>
          {active === true ? (
            <div>
              <label className="verde">activo</label>
            </div>
          ) : (
            <div>
              <label className="rojo">Inactivo</label>
            </div>
          )}
        </p> */}
      </div>
      <div className="acciones">

        {((censo === null) && (token.user.id_rol !== 3)) ? <Link to={`/viviendas/editar/${id_base}`} className="btn btn-azul" >
          <i className="fas fa-pen-alt" />
          Editar Asignacion de usuario en vivienda
        </Link> : null}



        {censo === null ? null : <Link to={`/viviendas/viewencuesta/${id_base}`} className="btn btn-verde" >
          <i className="fas fa-pen-alt" />
          view encuesta sincronizada
        </Link>}




        {/* 
        <Link to={`/pedidos/nuevo/${id_user}`} className="btn btn-amarillo">
          <i className="fas fa-plus" />
          Nuevo Pedido
        </Link> */}

        {/*   <button
          type="button"
          className="btn btn-rojo btn-eliminar"
          onClick={() => eliminarCliente(id_user)}
        >
          <i className="fas fa-times" />
          Eliminar Usuario
        </button> */}
      </div>
    </li>
  );
}
export default Cliente;
