import React, { Fragment } from 'react';
import { Link } from "react-router-dom";
const Hogares = (props) => {
    const goBack = () => {
        props.history.goBack()
    }
    //console.log(props.location.state.map((item) => console.log(item)));
    return (
        <Fragment>
            <h2>Lista de Hogares</h2>
            <label className="negrita mb-40">Para ingresar a un hogar, haga click en el seleccionado:</label>
            {props.location.state.map((item) => <div className=" ">
                <Link to={{ pathname: `/viviendas/hogares/hogar/${item.id}`, state: props.location.state[item.id - 1] }} className="btn btn-verde redondo">Hogar:{item.id}</Link>
            </div>)}
            <button className="btn btn-rojo ml-40 mt-100 redondo" type="button" onClick={goBack}>Volver</button>
        </Fragment>
    );
}

export default Hogares;