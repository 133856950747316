import React, { Fragment } from 'react';
import { Link } from "react-router-dom";
const Individuales = (props) => {
   // console.log(props.location.state.map((item) => console.log(item)));
    //console.log(props.location.state[0]);
    const goBack = () => {
        props.history.goBack()
    }

    return (

        <Fragment>
            <h2>Individuales</h2>
            {/*    {props.location.state.map((item) => <div className="enviar">
                <Link to={{ pathname: `/viviendas/individuales/hogar/${item.id}`, state: props.location.state[item.id - 1] }} className="btn btn-azul ml-40y">Hogar:{item.id}</Link>
            </div>)}
 */}
            {
                (props.location.state !== undefined ?

                    props.location.state
                        .map((item) =>
                            Object.keys(item.respuestas).map((item2) =>
                                <Fragment>
                                    {(item2 === "VI1") ?

                                        <Link to={{ pathname: `/viviendas/hogares/individuales/individual/${item.respuestas[item2]}`, state: props.location.state[item.respuestas[item2] - 1].individual }} className="btn btn-verde redondo ml-40">Ingresar al Individual numero:{item.respuestas[item2]}</Link>
                                        : null}

                                    <p className="campo">
                                        <label className="negrita">{item2}: </label>{item.respuestas[item2]}
                                    </p>
                                </Fragment>
                            )


                        )

                    : null)
            }
            <button className="btn btn-rojo redondo mt-40 ml-40" type="button" onClick={goBack}>Volver</button>
        </Fragment >
    );
}

export default Individuales;