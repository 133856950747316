import React, { createContext, useState, useEffect, useContext } from 'react';
import clienteAxios from "../config/axios";
import { CRMContext } from "../context/CRMContext";
// Crear el Context
export const UsuariosContext = createContext();

// Provider es donde se encuentran las funciones y state
const UsuariosProvider = (props) => {

    // crear el state del Context
    const [usuarios, guardarUsuarios] = useState([]);
    const [auth] = useContext(CRMContext);
    // ejecutar el llamado a la api

    useEffect(() => {
        if (auth.auth) {
            //console.log(auth.auth);
            const obtenerUsuarios = async () => {

                const usuarios = await clienteAxios.get(
                    `/usuarios/`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                });
                guardarUsuarios(usuarios.data.rows);
            }
            obtenerUsuarios();
        }
    }, [auth]);
    //console.log(usuarios);
    return (
        <UsuariosContext.Provider
            value={{
                usuarios
            }}
        >
            {props.children}
        </UsuariosContext.Provider>
    )
}
export default UsuariosProvider;