import React, { useEffect, useState, useContext } from "react";
import getUserData from '../../utils/getDataUser';

//import Spinner from "../layout/Spinner";
import { withRouter } from "react-router-dom";
//import rol from '../../utils/nivel';

import { CRMContext } from "../../context/CRMContext";


function Bievenida(props) {
  // Trabajar con el state
  // clientes = state,  guardarClientes = funcion para guardar el state

  // utilizar valores del context
  const [auth] = useContext(CRMContext);

  if (auth.token === "")
    props.history.push("/iniciar-sesion");


  const [token, guardarToken] = useState('');

  useEffect(() => {
    (localStorage.getItem('token') === null) && localStorage.setItem("token", "")
    guardarToken(getUserData()
    )
  }, [auth]);




  return (
    <>
      <h1 className="">Bienvenido al Gestor de viviendas</h1>
    
    </>


  );
}
export default withRouter(Bievenida);
