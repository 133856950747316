import React, { useEffect, useState, useContext, Fragment } from "react";
import clienteAxios from "../../config/axios";
import Cliente from "./Cliente";
import getUserData from '../../utils/getDataUser';

//import Spinner from "../layout/Spinner";
import { withRouter } from "react-router-dom";
//import rol from '../../utils/nivel';
import { PartidosContext } from "../../context/PartidosContext";
import { CRMContext } from "../../context/CRMContext";

function Clientes(props) {
  // Trabajar con el state
  // clientes = state,  guardarClientes = funcion para guardar el state
  const [clientes, guardarClientes] = useState([]);
  const [busqueda, guardarBusqueda] = useState({
    partido: '',
    segmento: '',
    estadoBusqueda: 'todas'
  });

  // utilizar valores del context
  const [auth] = useContext(CRMContext);
  const { partidos } = useContext(PartidosContext);
  const [partidosegmento, guardarpartidosegmento] = useState({});

  if (auth.token === "")
    props.history.push("/iniciar-sesion");

  const consultarAPI2 = async () => {
    try {
      /*    const clientesConsulta = await clienteAxios.get(`/listadofiltrado/${busqueda}`, {
           headers: {
             Authorization: `Bearer ${localStorage.getItem("token")}`,
           }
         }); */
      //console.log("Envio a node");
      // console.log(busqueda);
      const clienteConsulta2 = await clienteAxios.get(`/listadofiltrado/${busqueda.partido}/${busqueda.segmento}/${busqueda.estadoBusqueda}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      // colocar el resultado en el state
      guardarClientes(clienteConsulta2.data.rows);
      //console.log("================");
      // console.log(clientesConsulta.data);
    } catch (error) {
      // Error con authorizacion
      // console.log(error.response);
      if ((error.response.status = 500)) {
        props.history.push("/iniciar-sesion");
      }
    }
  };
  const guardarSelect1 = (e) => {
    // console.log(e.target.value);
    guardarBusqueda(
      {
        ...busqueda,
        [e.target.name]: e.target.value
      })
    //console.log("Llamamos a consultar Api");



  }
  const [token, guardarToken] = useState('');

  useEffect(() => {
    (localStorage.getItem('token') === null) && localStorage.setItem("token", "")
    guardarToken(getUserData()
    )
  }, [auth]);

  useEffect(() => {
    if (busqueda !== '') {
      const consultarAPI = async () => {
        // console.log(busqueda.partido);
        if (busqueda.partido === '') return null

        const clienteConsulta = await clienteAxios.get(
          `/busquedapartsegAll/${busqueda.partido}/`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        guardarpartidosegmento(clienteConsulta.data);

      };
      consultarAPI()/*  */
    }
  }, [busqueda.partido]);
  /* 
    useEffect(() => {
      if (auth.token !== "") {
        // Query a la API
        consultarAPI();
      } else {
        props.history.push("/iniciar-sesion");
      }
    }, []);
   */
  // Si el state esta como false
  /* if (!auth.auth) {
   -    props.history.push("/iniciar-sesion");
   -  } */

  //if (!clientes.length) return <Spinner />;
  //console.log(clientes);
  const handleChange = (e) => {
    guardarBusqueda({
      ...busqueda,
      [e.target.name]: e.target.value
    })
  }

  const agregarCliente = (e) => {
    e.preventDefault();
    busqueda.partido !== '' && busqueda.segmento !== '' ? consultarAPI2() : alert("Ambos valores son requeridos!!")
  };

  /*  console.log("rol");
   console.log(rol);
  */

  return (
    <Fragment>

      <h1 className="animate__animated animate__bounce">Busqueda de viviendas por:</h1>

      <form onSubmit={agregarCliente}>



        <select
          className="form-control select-css w400"
          name="partido"
          onChange={guardarSelect1}
          required
        >
          <option value="">-- Selecciona partido --</option>
          {partidos.map(partido => (
            <option
              key={partido.partido}
              value={partido.partido}
            >{partido.partido}</option>
          ))}
        </select>


        <select
          className="form-control select-css w400"
          name="segmento"
          onChange={handleChange}
          required
        >
          <option value="">-- Selecciona segmento --</option>
          {Object.keys(partidosegmento).length !== 0 ? partidosegmento.rows.map(segmento => (
            <option
              key={segmento.segmento}
              value={segmento.segmento}
            >{segmento.segmento}</option>
          )) : null}
        </select>





















        <select
          className="form-control select-css w400"
          name="estadoBusqueda"
          onChange={handleChange}
          required
        >
          <option value="todas">Todas las viviendas</option>
          <option value="sincronizadas">Sincronizadas</option>
          <option value="nosincronizadas">No sincronizadas</option>
          <option value="sinasignarusuario">Sin usuario asignado</option>
        </select>

        <input
          type="submit"
          className="redondo block btn btn-gris"
          value="Buscar Vivienda"
        /* disabled={ } */
        />



        {/*    <Link to={"/clientes/nuevo"} className="btn btn-verde nvo-cliente">
        <i className="fas fa-plus-circle"></i>
        agregar vivienda
      </Link> */}
      </form>
      <ul className="listado-clientes">
        {clientes.map((cliente) => (
          <Cliente key={cliente.id_base} cliente={cliente} />
        ))}
      </ul>
    </Fragment>
  );
}
export default withRouter(Clientes);
